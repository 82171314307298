import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextLanguagesPipe } from './text-languages/text-languages.pipe';
import { ImageToUrlPipe } from './imageToUrl/image-to-url.pipe';
import { CurrenciePipe } from './currencie/currencie.pipe';
import { DateTimeToTimePipe } from './date-time-to-time/date-time-to-time.pipe';
import { ToMoneyPipe } from './money-pipes/to-money.pipe';
import { ToHealthCreditsPipe } from './money-pipes/to-health-credits.pipe';
import { HealthcarepackagesTostringPipe } from './healthcarepackagestostring/healthcarepackages-tostring.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
@NgModule({
  declarations: [
    TextLanguagesPipe,
    ImageToUrlPipe,
    CurrenciePipe,
    DateTimeToTimePipe,
    ToMoneyPipe,
    ToHealthCreditsPipe,
    HealthcarepackagesTostringPipe,
    TruncatePipe,
  ],
  imports: [CommonModule],
  exports: [
    TextLanguagesPipe,
    ImageToUrlPipe,
    CurrenciePipe,
    DateTimeToTimePipe,
    HealthcarepackagesTostringPipe,
    TruncatePipe,
  ],
})
export class PipesModule {}
