import { IBuyedpackage } from './Buyedpackage';
import { Adresse, Content } from './Common';
import { IGroup } from './Group';
import { ImageProfil } from './ImageProfil';

export interface IContact {
  id: number;
  user_id: number;
  group_id: number;
  last_activity: any;
  adresse: Adresse;
  tel: string;
  email?: string;
  gender?: string;
  group?: IGroup;
  buyedpackages?: IBuyedpackage[];
  created_at: string;
  updated_at: string;
  image_profil: ImageProfil;
  has_whatsapp?: boolean;
}

export interface ContactPageResponse {
  status_code: number;
  healthcareproviders: Content<IContact>;
}

export interface ContactCommonResponse {
  contact: IContact;
}

export interface IContactStore {
  tel: string;
  language_id: number;
  adresse: Adresse;
  has_whatsapp?: boolean;
}

export class ContactStore implements IContactStore {
  tel: string;
  group_id: number;
  language_id: number;
  adresse: Adresse;
  has_whatsapp?: boolean;
  email?: string;

  constructor(
    tel: string,
    group_id: number,
    language_id: number,
    adresse: Adresse,
    has_whatsapp?: boolean,
    email?: string
  ) {
    this.tel = tel;
    this.group_id = group_id;
    this.language_id = language_id;
    this.adresse = adresse;
    this.has_whatsapp = has_whatsapp;
    this.email = email;
  }
}

export class Contact {
  id?: number;
  user_id?: number;
  group_id?: number;
  adresse?: Adresse;
  created_at?: string;
  updated_at?: string;
  image_profil?: ImageProfil;

  constructor(
    id?: number,
    user_id?: number,
    group_id?: number,
    adresse?: Adresse,
    created_at?: string,
    updated_at?: string,
    image_profil?: ImageProfil
  ) {
    this.id = id;
    this.user_id = user_id;
    this.group_id = group_id;
    this.adresse = adresse;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.image_profil = image_profil;
  }
}
