import { Injectable } from '@angular/core';
import { GroupType } from 'src/app/core/enums/enums';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import {
  IGoogleLogin,
  ILoginResponse,
  IUser,
  Login,
} from 'src/app/core/models/models';
import { Observable, throwError } from 'rxjs';
import {
  IVerificationResponse,
  VerificationCode,
} from 'src/app/core/models/VerificationCode';
import {
  RestAuthentificationService,
  UseCaseRoleService,
  UserService,
} from 'src/app/core/rest-services/rest-services';
import { SessionService } from 'src/app/core/services/session.service';
import { CustomNavigationService } from 'src/app/core/services/custom-navigation.service';
import { ServicesService } from 'src/app/core/services/services.service';
import { Country, OptionObject } from 'src/app/core/utils/type';
import { getCountries } from 'src/app/core/utils/country';
import { Router } from '@angular/router';
import { OtpLimitModalComponent } from 'src/app/pages/containers/components/otp-limit-modal/otp-limit-modal.component';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthentificationService {
  currentLocation?: string;
  constructor(
    public navigationService: CustomNavigationService,
    public authService: RestAuthentificationService,
    public userService: UserService,
    public services: ServicesService,
    public sessionService: SessionService,
    public translateService: TranslateService,
    private notificationService: NotificationService,
    private useCaseRole: UseCaseRoleService,
    public route: Router
  ) {
    this.currentLocation = this.route.url;
  }

  public sentVerificationCode(
    identifierModel: VerificationCode
  ): Observable<any> {
    return this.authService.getVerificationCode(identifierModel).pipe(
      tap((res: IVerificationResponse) => {
        this.notificationService.success(
          this.translateService.instant('sign-in.verification-code')
        );
      }),
      switchMap((res: IVerificationResponse) => {
        return new Observable(observer => {
          observer.next(res);
          observer.complete();
        });
      }),
      catchError((err: IVerificationResponse) => {
        if (err.status_code === 400) {
          if (err.translate === 'errors.wait') {
            this.services.modalService.openModal(OtpLimitModalComponent, {
              width: '300px',
              height: 'auto',
              data: {},
            });
          } else if (err.translate === 'errors.blocked') {
            this.notificationService.danger(
              this.translateService.instant('errors.blocked-account')
            );
          }
        }
        return throwError(err);
      })
    );
  }

  public loginWithVerificationCode(
    loginModel: Login,
    data: any
  ): Observable<any> {
    return this.authService.loginWithVerificationCode(loginModel).pipe(
      tap((res: ILoginResponse) => {
        if (res.status_code === 401) {
          const message = this.translateService.instant(res.message ?? '');
          if (message !== '') {
            this.notificationService.danger(message);
            this.services.eventService.publish('hide:loading');
          }
        } else {
          this.sessionService.storeAccessToken(res.access_token);
          if (data) {
            this.notificationService.success(
              this.services.translateService.instant(
                'common.invitation-success'
              )
            );
          }
          this.services.eventService.publish('hide:loading');
          this.sessionService.deleteUser();
          this.sessionService.storeUser(res.user);
          this.storeCurrencie(res.user);
          this.services.eventService.publish('login:end', res.user);
          this.services.sessionService.storeCloseAlertState();
        }
      }),
      switchMap((res: ILoginResponse) => {
        return new Observable(observer => {
          observer.next(res);
          observer.complete();
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  loginWithGoogle(loginModel: IGoogleLogin, data?: any): Observable<any> {
    return this.authService.loginWithGoogle(loginModel).pipe(
      tap((res: ILoginResponse) => {
        if (res.status_code === 401) {
          const message = this.translateService.instant(res.message ?? '');
          if (message !== '') {
            this.notificationService.danger(message);
            this.services.eventService.publish('hide:loading');
          }
        } else {
          this.sessionService.storeAccessToken(res.access_token);
          this.services.eventService.publish('hide:loading');
          this.sessionService.deleteUser();
          this.sessionService.storeUser(res.user);
          this.storeCurrencie(res.user);
          this.services.eventService.publish('login:end', res.user);
          this.services.sessionService.storeCloseAlertState();
          if (data.invitation?.invitation_key) {
            this.useCaseRole
              .addPublicMember(data.invitation?.invitation_key)
              .subscribe({
                next: () => {
                  this.notificationService.success(
                    this.translateService.instant('common.add-pending-member')
                  );
                },
                error: (err: any) => {
                  this.notificationService.danger(
                    this.services.translateService.instant(
                      err.translate ?? 'errors.generic'
                    )
                  );
                },
              });
          }
        }
      }),
      switchMap((res: ILoginResponse) => {
        return new Observable(observer => {
          observer.next(res);
          observer.complete();
        });
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  storeCurrencie(user: IUser): void {
    this.sessionService.storeUserCurrencie(user.currencie);
    this.sessionService.storeCurrencie(user.currencie);
    this.changleRole(user);
  }

  countryListToOptionObject(): OptionObject[] {
    const countryOptions: OptionObject[] = [];
    const countries: Country[] = getCountries({ longList: true });
    countries.forEach(country => {
      countryOptions.push({
        label: country.code,
        value: country.name,
      });
    });
    return countryOptions;
  }

  changleRole(user: IUser): void {
    let role = null;
    if (this.sessionService.hasRole(GroupType.Admin)) {
      if (user.adminroles && user.adminroles.length > 0) {
        role = this.sessionService.getRole(GroupType.Admin);
      } else {
        role = this.sessionService.getRole(GroupType.Kimbo);
      }
      this.services.roleService.changeRole(role);
    } else if (this.sessionService.hasRole(GroupType.Companie)) {
      role = this.sessionService.getRole(GroupType.Companie);
      this.services.roleService.changeRole(role);
    } else if (this.sessionService.hasRole(GroupType.Benefit)) {
      role = this.sessionService.getRole(GroupType.Benefit);
      this.services.roleService.changeRole(role);
    } else if (this.sessionService.hasRole(GroupType.Hcp)) {
      role = this.sessionService.getRole(GroupType.Hcp);
      this.services.roleService.changeRole(role);
    } else if (this.sessionService.hasRole(GroupType.Kimbo)) {
      role = this.sessionService.getRole(GroupType.Kimbo);
      this.services.roleService.changeRole(role, this.currentLocation);
    }
  }

  cleanStorage() {
    this.sessionService.deleteAccessToken();
    this.sessionService.deleteCurrentRole();
    this.sessionService.deleteCurrencie();
    this.sessionService.deleteUserCurrencie();
    this.sessionService.deleteHcpCurrencie();
  }
}
