import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GroupType, UserType } from './core/enums/enums';
import { CustomNavigationService } from './core/services/custom-navigation.service';
import { EventService } from './core/services/event.service';
import { LanguageService } from './core/services/language.service';
import { ModalService } from './core/services/modal.service';
import { RoleService } from './core/services/role.service';
import { ServicesService } from './core/services/services.service';
import { SessionService } from './core/services/session.service';
import { StorageService } from './core/services/storage.service';
import { UtilsService } from './core/services/utils.service';
import { PushNotificationService } from './core/services/push-notification.service';
import { environment } from 'src/environments/environment';
import { AppUpdateService } from './core/services/app-update.service';
import { Subscription } from 'rxjs';
import { extractQueryParameters } from './core/utils/misc';
import {
  RestAuthentificationService,
  UseCaseRoleService,
} from './core/rest-services/rest-services';
import { Invitation } from './core/models/models';
import { NotificationService } from './core/services/notification.service';
import { LoginModalComponent } from './shared-components/login/login-modal/login-modal.component';
import { BROWSER_ENUM } from './core/utils/type';
import { KimboMatIconRegistry } from './core/services/kimbo-mat-icon-registry.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  appVersion = '';
  isLoading = false;
  isLoadingLabel = 'Loading...';
  browser: BROWSER_ENUM = BROWSER_ENUM.UNKNOWN;
  showBrowerAlert = false;
  $browerCompatibilitySubscription!: Subscription;

  constructor(
    public services: ServicesService,
    public translateService: TranslateService,
    public languageService: LanguageService,
    public roleService: RoleService,
    public storageService: StorageService,
    public pushNotificationService: PushNotificationService,
    public eventService: EventService,
    public utilsService: UtilsService,
    public customNavigationService: CustomNavigationService,
    public sessionService: SessionService,
    public modalService: ModalService,
    public appUpdateService: AppUpdateService,
    public useCaseRole: UseCaseRoleService,
    private notificationService: NotificationService,
    private authenticationService: RestAuthentificationService,
    private kimboIconRegistry: KimboMatIconRegistry
  ) {
    translateService.addLangs(['en', 'fr']);
    translateService.setDefaultLang('en');
    const lang = this.sessionService.getLanguage();
    if (lang) {
      translateService.use(lang.match(/en|fr/) ? lang : 'en');
    } else {
      const browserLang = translateService.getBrowserLang();
      if (browserLang)
        translateService.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    }

    this.isLoadingLabel =
      this.translateService.currentLang === 'en'
        ? 'Loading...'
        : 'Chargement...';
    this.appVersion = environment.appVersion;

    this.services.sessionService = this.sessionService;
    this.services.languagesService = this.languageService;
    this.services.roleService = this.roleService;
    this.services.utilsService = this.utilsService;
    this.services.storageService = this.storageService;
    this.services.translateService = this.translateService;
    this.services.eventService = this.eventService;
    this.services.customNavigationService = this.customNavigationService;
    this.services.modalService = this.modalService;
    this.services.pushNotificationService = this.pushNotificationService;
    this.authenticationService.getlocation().subscribe({
      next: (res: any) => {
        this.services.sessionService.deleteCountry();
        this.services.sessionService.storeCountry(res);
      },
      error: (err: any) => {
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
    this.browser = this.utilsService.detectBrowser_v2();
  }

  ngOnInit(): void {
    //Because query external parameters for angular if too busy 😅
    const queryParams = extractQueryParameters(window.location.href, [
      'mode',
      'key',
      'identification',
      'invitation_key',
    ]);

    if (queryParams) {
      if (!this.sessionService.isLoggedIn() || this.sessionService.isGuest()) {
        if (
          queryParams['mode'] ||
          queryParams['key'] ||
          queryParams['invitation_key']
        ) {
          this.isLoading = true;
          this.customNavigationService.goToWithParams('login', queryParams);
        }
      } else if (
        this.sessionService.isLoggedIn() &&
        !this.sessionService.isGuest()
      ) {
        if (queryParams['invitation_key']) {
          this.addPendindInvitation(queryParams['invitation_key']);
        } else {
          this.invite(queryParams);
        }
      }
    } else if (
      !window.location.href.includes('hcp-registration') &&
      !window.location.href.includes('login')
    ) {
      this.redirectToCurrentRole();
    }

    this.$browerCompatibilitySubscription =
      this.services.eventService.subscribe('set:browser-compatibility', () => {
        this.showBrowerAlert =
          this.services.sessionService.isLoggedIn() &&
          !this.services.sessionService.isGuest() &&
          (this.services.sessionService.getCurrentRole().group.type ===
            GroupType.Benefit ||
            this.services.sessionService.getCurrentRole().group.type ===
              GroupType.Companie) &&
          this.browser !== BROWSER_ENUM.CHROME &&
          this.browser !== BROWSER_ENUM.FIRE_FOX &&
          this.browser !== BROWSER_ENUM.EDGE;
      });
  }

  ngAfterViewInit(): void {
    this.showBrowerAlert =
      this.services.sessionService.isLoggedIn() &&
      !this.services.sessionService.isGuest() &&
      (this.services.sessionService.getCurrentRole().group.type ===
        GroupType.Benefit ||
        this.services.sessionService.getCurrentRole().group.type ===
          GroupType.Companie) &&
      this.browser !== BROWSER_ENUM.CHROME &&
      this.browser !== BROWSER_ENUM.FIRE_FOX &&
      this.browser !== BROWSER_ENUM.EDGE;
  }

  ngOnDestroy(): void {
    this.$browerCompatibilitySubscription &&
      this.$browerCompatibilitySubscription.unsubscribe();
  }

  redirectToCurrentRole(): void {
    if (
      this.sessionService.getUser() &&
      this.sessionService.getUser().roles?.length! === 0 &&
      this.sessionService.getUser().user_type !== UserType.Guest
    ) {
      this.sessionService.clearStorage();
    }

    const currentRole = this.services.roleService.getCurrentRole();

    if (currentRole) {
      if (currentRole.group.type === GroupType.Admin) {
        const adminInitialRoute = this.sessionService.initialRoute();
        if (adminInitialRoute) {
          this.customNavigationService.goToWithState(adminInitialRoute, {
            state: { dashBoardRedirection: true },
          });
        }
      } else if (currentRole.group.type === GroupType.Benefit) {
        this.customNavigationService.goTo('/benefit');
      } else if (currentRole.group.type === GroupType.Companie) {
        this.customNavigationService.goTo('/corporate');
      } else if (currentRole.group.type === GroupType.Hcp) {
        this.customNavigationService.goTo('/hcp');
      } else if (currentRole.group.type === GroupType.Kimbo) {
        this.customNavigationService.goTo('/kimbo');
      }
    } else {
      this.sessionService.clearStorage();
    }
  }

  invite(queryParams: any): void {
    this.redirectToCurrentRole();
    this.isLoading = true;
    this.useCaseRole.invite({ key: queryParams['key'] }).subscribe({
      next: (res: Invitation) => {
        setTimeout(() => {
          if (res.status === 400) {
            this.notificationService.warning(
              this.services.translateService.instant(
                'common.wrong-invitation-account-message',
                {
                  identifier: queryParams['identification'],
                }
              )
            );
            this.services.modalService
              .openModal(LoginModalComponent, {
                width: '950px',
                height: 'auto',
                data: {
                  caller: '_SIGN_IN',
                  invitation: {
                    key: queryParams['key'],
                    mode: queryParams['mode'],
                    identifier: queryParams['identification'],
                  },
                },
              })
              .subscribe(res => {
                //this.redirectToCurrentRole();
              });
          } else if (res.status === 200) {
            this.notificationService.success(
              this.services.translateService.instant(
                'common.invitation-success'
              )
            );
          } else if (res.status === 404) {
            this.notificationService.warning(
              this.services.translateService.instant(
                'common.invitation-not-exist-label'
              )
            );
          }
        }, 1000);
      },
      error: () => {
        this.notificationService.danger(
          this.services.translateService.instant('errors.generic')
        );
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  addPendindInvitation(uuid: string): void {
    this.redirectToCurrentRole();
    this.isLoading = true;
    this.useCaseRole.addPublicMember(uuid).subscribe({
      next: (res: any) => {
        this.notificationService.success(
          this.translateService.instant('common.add-pending-member')
        );
      },
      error: err => {
        this.notificationService.danger(
          this.services.translateService.instant(
            err.translate ?? 'errors.generic'
          )
        );
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }

  dismissBrowserComptilityAlert(): void {
    this.showBrowerAlert = false;
  }
}
