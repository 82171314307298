/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Permission } from 'src/app/core/enums/enums';
import {
  GroupeResponse,
  IGroup,
  IPublicMember,
  IRole,
  IUser,
  ImageProfil,
  RoleStore,
} from 'src/app/core/models/models';
import {
  RestAuthentificationService,
  RestRoleService,
  UseCaseRoleService,
} from 'src/app/core/rest-services/rest-services';
import { ServicesService } from 'src/app/core/services/services.service';
import { Country, OptionObject, Phone } from 'src/app/core/utils/type';
import { MaterialConfirmDialogComponent } from 'src/app/shared-components/material-confirm-dialog/material-confirm-dialog.component';
import { ConfirmationComponent } from '../create-edit-roles/confirmation/confirmation.component';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { isValidPhoneNumber, isValidEmail } from 'src/app/core/utils/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { INPUT_CLASSES } from 'src/app/core/utils/constant';
import { getCountries } from 'src/app/core/utils/country';
import { Clipboard } from '@angular/cdk/clipboard';
import { PendingMembersComponent } from './pending-members/pending-members.component';

type Member = {
  roleId?: number;
  groupId?: number;
  user_id?: number;
  name?: string;
  tel?: string;
  email?: string;
  image?: ImageProfil;
  permissions?: string[];
  active?: boolean;
};
@Component({
  selector: 'app-group-members',
  templateUrl: './group-members.component.html',
  styleUrls: ['./group-members.component.scss'],
})
export class GroupMembersComponent implements OnInit {
  isGettingMember = false;
  currentRole!: IRole;
  isOwer = false;
  groupRole: IRole[] = [];
  invitationsMember: any[] = [];
  formatedMemberList: Member[] = [];
  isCreatingMember = false;
  isEditingMember = false;
  isCreateEditModalOpen = false;
  addMemberWithPhone = true;
  phoneModel?: Phone;
  memberEmail = '';
  formCreateRole!: FormGroup;
  memberToUpdate!: Member;
  pOwer = Permission.Owner;
  pMember = Permission.Member;
  numberOfGroupMember = 0;
  user!: IUser;
  input_cls = INPUT_CLASSES;
  country = '';
  permission = Permission;
  groupLink!: string;
  invitationMessage!: string;
  shareLink!: string;
  members!: IPublicMember[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { selectedRole: OptionObject },
    public dialogRef: MatDialogRef<GroupMembersComponent>,
    private services: ServicesService,
    private roleService: RestRoleService,
    private useCaseRoleService: UseCaseRoleService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    public authService: RestAuthentificationService,
    private clipboard: Clipboard,
    private fb: FormBuilder
  ) {
    this.currentRole = this.services.sessionService.getCurrentRole();
    this.country = this.services.sessionService.getCountry().country;
    this.isOwer = this.currentRole.permissions.includes(Permission.Owner);
  }

  ngOnInit(): void {
    this.user = this.services.sessionService.getUser();
    this.getRoles(this.currentRole.group_id);
    this.fetchPendingMember();
    this.formCreateRole = this.fb.group({
      permission: ['member', Validators.required],
    });
  }

  getRoles(group_id: number): void {
    this.isGettingMember = true;
    this.roleService.index(group_id).subscribe({
      next: (userRes: IRole[]) => {
        this.groupLink =
          window.location.origin +
          '?' +
          encodeURI('invitation_key=' + userRes[0].group.uuid);

        this.invitationMessage =
          this.translateService.instant('common.public-invitation-message', {
            group: userRes[0].group.name,
          }) +
          '\n\n' +
          this.groupLink;

        this.shareLink =
          this.translateService.instant('common.public-invitation-message', {
            group: userRes[0].group.name,
          }) +
          '%0A%0A' +
          this.groupLink;
        this.groupRole = userRes;
        this.formatedMemberList = [];
        this.getMemberList();
        this.getMemberListInvitate();
      },
      error: () => {
        this.isGettingMember = false;
      },
      complete: () => {
        this.isGettingMember = false;
      },
    });
  }

  getMemberList(): void {
    this.numberOfGroupMember = 0;
    this.groupRole.forEach((role: IRole, index) => {
      const member: Member = {};
      member.groupId = role.group_id;
      member.roleId = role.id;
      member.user_id = role.user_id;
      member.permissions = role.permissions;
      if (role.user_id !== null) {
        member.name =
          role.user!.data &&
          (role.user!.data.firstName || role.user!.data.lastName)
            ? role.user!.data.firstName + ' ' + role.user!.data.lastName
            : '';
        member.tel = role.user!.tel ? role.user!.tel : '';
        member.email = role.user!.email ? role.user!.email : '';
        member.image = role.user!.image_profil;
        member.active = role.user!.public_properties.active;
      } else {
        member.name = '';
      }
      if (!this.formatedMemberList.find(mem => mem.roleId === member.roleId))
        this.formatedMemberList.push(member);
    });
    this.numberOfGroupMember += this.groupRole.length;
    const adminUser = this.formatedMemberList.filter((mem: Member) =>
      mem.permissions?.includes(Permission.Owner)
    );
    const memberUser = this.formatedMemberList.filter(
      (mem: Member) =>
        mem.permissions?.includes(Permission.Member) &&
        !mem.permissions?.includes(Permission.Owner)
    );
    this.formatedMemberList = [...adminUser, ...memberUser];
    this.isGettingMember = false;
  }

  getMemberListInvitate(): void {
    this.useCaseRoleService
      .listInvitations(this.currentRole.group_id)
      .subscribe({
        next: (res: any) => {
          this.invitationsMember = res;
          this.numberOfGroupMember += this.invitationsMember.length;
        },
        error: () => {
          this.isGettingMember = false;
        },
        complete: () => {
          this.isGettingMember = false;
        },
      });
  }

  removeInvitation(event: MouseEvent, member: any) {
    this.isGettingMember = true;
    event.stopPropagation();
    this.useCaseRoleService
      .removeInvitation(this.currentRole.group_id, { uuid: member.uuid })
      .subscribe({
        next: () => {
          this.isGettingMember = false;

          this.getRoles(this.currentRole.group_id);
        },
        error: () => {
          this.isGettingMember = false;
        },
        complete: () => {
          this.isGettingMember = false;
        },
      });
  }

  saveRoleMember(): void {
    this.isGettingMember = true;
    const expectedRole = this.initExpectedMember();
    this.roleService.store(expectedRole).subscribe({
      next: (res: any) => {
        if (res.translate) {
          this.services.modalService
            .openModal(ConfirmationComponent, {
              width: '350px',
              height: 'auto',
              data: {
                contact: expectedRole.tel ?? expectedRole.email,
              },
            })
            .subscribe((confirm?) => {
              if (confirm) {
                this.roleService.inviteMember(expectedRole).subscribe({
                  next: () => {
                    this.notificationService.success(
                      this.translateService.instant('common.invitation-send')
                    );
                    this.numberOfGroupMember = this.formatedMemberList.length;
                    this.getMemberListInvitate();
                  },
                  error: () => {
                    this.isGettingMember = false;
                  },
                  complete: () => {
                    this.isGettingMember = false;
                  },
                });
              } else {
                this.isGettingMember = false;
              }
            });
        } else {
          this.getRoles(this.currentRole.group_id);
          this.notificationService.success(
            this.translateService.instant('common.invitation-send')
          );
        }
        this.phoneModel = undefined;
        this.memberEmail = '';
      },
      error: (err: any) => {
        this.isGettingMember = false;
        this.notificationService.danger(
          this.translateService.instant(err.translate)
        );
      },
      complete: () => {
        this.closeEditRoleModal();
      },
    });
  }

  saveMember(): void {
    const member = this.formatedMemberList.find(
      mem =>
        (mem.email === this.memberEmail && this.memberEmail.trim() !== '') ||
        (mem.tel === this.phoneModel?.phone && mem.tel?.trim() !== '')
    );

    const existingMember = this.invitationsMember.find(
      mem =>
        mem.identifiant === this.memberEmail ||
        mem.identifiant === this.phoneModel?.phone
    );

    if ((!this.isEditingMember && member) || existingMember) {
      this.notificationService.warning(
        this.translateService.instant('common.member-exist-label')
      );
    } else {
      if (this.isCreatingMember && !this.isEditingMember) {
        if (this.addMemberWithPhone) {
          if (!this.phoneModel || !isValidPhoneNumber(this.phoneModel.phone)) {
            this.notificationService.danger(
              this.translateService.instant('errors.phone-error')
            );
            return;
          } else {
            this.saveRoleMember();
          }
        } else {
          if (!isValidEmail(this.memberEmail)) {
            this.notificationService.danger(
              this.translateService.instant('errors.invalid-email')
            );
            return;
          } else {
            this.saveRoleMember();
          }
        }
      } else if (this.isEditingMember) {
        this.update();
      }
    }
  }

  _isValidPhoneNumber(phone: string): boolean {
    return isValidPhoneNumber(phone);
  }

  _isValidEmail(email: string): boolean {
    return isValidEmail(email);
  }

  countryListToOptionObject(): OptionObject[] {
    const countryOptions: OptionObject[] = [];
    const countries: Country[] = getCountries({ longList: true });
    countries.forEach(country => {
      countryOptions.push({
        label: country.code,
        value: country.name,
      });
    });
    return countryOptions;
  }

  update(): void {
    this.isGettingMember = true;
    const expectedRole = this.initExpectedMemberUpdate(
      this.formCreateRole.value
    );
    this.roleService
      .update(this.memberToUpdate.roleId!, expectedRole)
      .subscribe({
        next: () => {
          this.notificationService.success(
            this.translateService.instant(
              'kimbo-layout.success-member-updated',
              {
                name: this.memberToUpdate.name!,
              }
            )
          );
          this.formatedMemberList = [];
          this.getRoles(this.currentRole.group_id);
        },
        error: () => {
          this.isGettingMember = false;
          this.notificationService.danger(
            this.translateService.instant('errors.generic')
          );
        },
        complete: () => {
          this.isGettingMember = false;
          this.closeEditRoleModal();
        },
      });
  }

  deleteMember(event: MouseEvent, member: Member): void {
    event.stopPropagation();
    this.services.modalService
      .openModal(MaterialConfirmDialogComponent, {
        width: '500px',
        height: 'auto',
      })
      .subscribe(action => {
        if (action === '_YES') {
          this.delete(member);
        }
      });
  }

  delete(member: Member): void {
    this.isGettingMember = true;
    this.roleService.delete(member.roleId!).subscribe({
      next: (resRole: IRole) => {
        this.getRoles(this.currentRole.group_id);
        this.notificationService.success(
          this.translateService.instant('kimbo-layout.success-member-deleted', {
            name: member.name!,
          })
        );
      },
      error: () => {
        this.isGettingMember = false;
        this.notificationService.danger(
          this.translateService.instant('errors.generic')
        );
      },
      complete: () => {},
    });
  }

  initExpectedMemberUpdate(formValue: any): RoleStore {
    let roleStore = new RoleStore();
    const permissions: string[] = [];
    permissions.push(formValue.permission);

    if (this.isCreatingMember) {
      if (this.addMemberWithPhone) {
        roleStore = new RoleStore(
          this.phoneModel?.phone,
          this.currentRole.group_id,
          permissions
        );
      } else {
        roleStore = new RoleStore(
          undefined,
          this.currentRole.group_id,
          permissions,
          this.memberEmail
        );
      }
    } else if (this.isEditingMember) {
      roleStore = new RoleStore(
        undefined,
        this.currentRole.group_id,
        permissions
      );
    }
    return roleStore;
  }

  initExpectedMember(): any {
    const roleStore = {
      ...(this.addMemberWithPhone && { tel: this.phoneModel?.phone }),
      ...(!this.addMemberWithPhone && { email: this.memberEmail }),
      group_id: this.currentRole.group_id,
      url: window.location.origin,
    };

    return roleStore;
  }

  handlePhoneEvent(phone: Phone): void {
    this.phoneModel = phone;
  }

  setMemberEmail(event: any): void {
    this.memberEmail = event?.target.value;
  }

  toggleAggingMethod(): void {
    this.addMemberWithPhone = !this.addMemberWithPhone;
    this.memberEmail = '';
    this.phoneModel = undefined;
  }

  closeEditRoleModal(): void {
    this.isCreatingMember = false;
    this.isEditingMember = false;
    this.isCreateEditModalOpen = false;
    this.memberEmail = '';
    this.phoneModel = undefined;
  }

  closeMainModal(): void {
    this.dialogRef.close();
  }

  toggleCreateOverlayVisibility(data?: Member): void {
    if (this.isOwer) {
      if (data) {
        if (
          this.currentRole.group.user_admin_id !== data.user_id &&
          data.user_id !== this.user.id
        ) {
          this.isEditingMember = true;
          this.formCreateRole.patchValue({
            permission:
              data && data.permissions?.includes(Permission.Owner)
                ? 'owner'
                : 'member',
          });
          this.memberToUpdate = data;
          this.isCreateEditModalOpen = true;
        }
      } else {
        this.isCreateEditModalOpen = !this.isCreateEditModalOpen;
        this.isEditingMember = false;
        this.isCreatingMember = true;
      }
    }
  }

  copyText() {
    this.clipboard.copy(this.invitationMessage);
    this.notificationService.success(
      this.translateService.instant('admin-fields.copy-text-message')
    );
  }

  openPendingMemberModal() {
    this.services.modalService
      .openModal(PendingMembersComponent, {
        width: '400px',
        height: 'auto',
        data: {
          members: this.members,
          group_id: this.currentRole.group_id,
        },
      })
      .subscribe(() => {
        this.fetchPendingMember();
        this.getRoles(this.currentRole.group_id);
      });
  }

  fetchPendingMember() {
    this.useCaseRoleService
      .listWaitingMember(this.currentRole.group_id)
      .subscribe({
        next: (res: IPublicMember[]) => {
          this.members = res;
        },
        error: (err: any) => {},
        complete: () => {},
      });
  }
}
