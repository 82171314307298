import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, fromEvent, map, merge, of, timer } from 'rxjs';
import { GroupType } from 'src/app/core/enums/enums';
import { SessionService } from 'src/app/core/services/session.service';
import { CustomNavigationService } from 'src/app/core/services/custom-navigation.service';
import { OptionObject } from 'src/app/core/utils/type';
import { IRole, IUser } from 'src/app/core/models/User';
import { ServicesService } from 'src/app/core/services/services.service';
import { ProfileComponent } from '../components/profile/profile.component';
import { BreakpointMatcherService } from 'src/app/core/services/breakpoint-matcher.service';
import { BreakpointState } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IGroup } from 'src/app/core/models/Group';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/core/rest-services/rest-services';
import { AppUpdateService } from 'src/app/core/services/app-update.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DownloadAppComponent } from '../components/download-app/download-app.component';
import { UtilsService } from 'src/app/core/services/utils.service';
import { PreLoginInfosComponent } from '../../../shared-components/login/pre-login-infos/pre-login-infos.component';
import { LoginModalComponent } from '../../../shared-components/login/login-modal/login-modal.component';
import * as moment from 'moment';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BottomSheetService } from 'src/app/core/services/bottom-sheet.service';
import { MatSidenavContainer } from '@angular/material/sidenav';
import { IBuyedpackage } from 'src/app/core/models/Buyedpackage';
import { ReferalModalComponent } from '../../kimbo/components/referal-modal/referal-modal.component';

@Component({
  selector: 'app-application-layout',
  templateUrl: './application-layout.component.html',
  styleUrls: ['./application-layout.component.scss'],
})
export class ApplicationLayoutComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('drawer') sidenav!: MatSidenavContainer;
  params = {
    size: 'medium',
    theme: 'blue',
  };
  selectedOption!: OptionObject;
  isVisible = false;
  visibilitySubscription?: Subscription;
  isModalProfilOpen = false;
  showRoleDropdown = true;
  currentRole!: IRole;
  currentRoleType!: GroupType;
  _user!: IUser;
  breackpointMatche = false;
  $rolechange!: Subscription;
  $createGroup!: Subscription;
  $updateGroup!: Subscription;
  activeLocation!: string | undefined;
  $languageChange!: Subscription;
  $updateUser!: Subscription;
  $showloading!: Subscription;
  $hideloading!: Subscription;
  badge = false;
  enviromentname = '';
  appVersion = '';
  groupType = GroupType;
  image_profil = '';
  loadingEventsCount = 0;
  isGuest = false;
  $loginEnd!: Subscription;
  isLoading = false;
  activeRoute = '';
  mobileDevice = '';
  download_app_state!: string;
  user!: IUser;
  networkStatus = false;
  $networkStatus: Subscription = Subscription.EMPTY;
  currentLanguage = 'fr';
  drawerOptions = {
    bottom: 0,
    fixed: true,
    top: 0,
  };
  $showConversationLogs!: Subscription;
  buyedPackage!: IBuyedpackage | undefined; // this for conversation heading
  showConversationLogsClose$!: Subscription;

  constructor(
    public breakpointMatcherService: BreakpointMatcherService,
    public languageService: TranslateService,
    public sessionService: SessionService,
    public navigationService: CustomNavigationService,
    public services: ServicesService,
    public router: Router,
    public AppUpdateService: AppUpdateService,
    public notificationService: NotificationService,
    public translateService: TranslateService,
    public userService: UserService,
    private bottomSheet: MatBottomSheet,
    public utilsService: UtilsService,
    private snackbar: MatSnackBar,
    private bottomSheetService: BottomSheetService
  ) {
    this.sessionService.updateUserStorage();
    this.user = this.sessionService.getUser();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activeRoute = event.urlAfterRedirects;
      }
    });
    this.services.sessionService.storePushN();
    this.download_app_state = this.sessionService.getDownloadAppState();
    this.currentLanguage = this.translateService.currentLang;
  }

  ngAfterViewInit(): void {
    const startDate = moment(this.user.created_at);
    const days = moment().diff(startDate, 'days');
    if (
      (this.mobileDevice == 'iOS' ||
        this.mobileDevice == 'Android' ||
        this.mobileDevice == 'Windows Phone') &&
      !this.activeRoute.includes('/admin') &&
      !this.download_app_state &&
      !this.utilsService.isInStandaloneMode() &&
      days >= 2
    ) {
      this.bottomSheet
        .open(DownloadAppComponent)
        .afterDismissed()
        .subscribe(() => {
          this.services.sessionService.storeDownloadAppState();
        });
    }
    if (this.services.sessionService.isGuest()) {
      timer(40000).subscribe(() => {
        if (
          this.services.sessionService.isGuest() &&
          !this.services.sessionService.getRequestToLogin()
        ) {
          this.openPreAuthModal();
        }
      });
    }
  }

  openPreAuthModal(): void {
    this.services.sessionService.storeRequestToLogin();
    this.services.modalService
      .openModal(PreLoginInfosComponent, {
        width: '400px',
        height: 'auto',
        disableClose: true,
        data: {
          caller: '_STEPPER',
        },
      })
      .subscribe(res => {
        if (
          res?.caller === '_SIGN_IN' ||
          res?.caller == '_SIGN_UP' ||
          res === '_SIGN_IN' ||
          res == '_SIGN_UP'
        ) {
          this.services.modalService.openModal(LoginModalComponent, {
            width: '950px',
            height: 'auto',
            disableClose: true,
            data: {
              caller: res?.caller,
            },
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.$rolechange && this.$rolechange.unsubscribe();
    this.$updateUser && this.$updateUser.unsubscribe();
    this.$updateGroup && this.$updateGroup.unsubscribe();
    this.$showloading && this.$showloading.unsubscribe();
    this.$hideloading && this.$hideloading.unsubscribe();
    this.$loginEnd && this.$loginEnd.unsubscribe();
    this.$networkStatus && this.$networkStatus.unsubscribe();
    this.$showConversationLogs?.unsubscribe();
    this.showConversationLogsClose$?.unsubscribe();
  }

  ngOnInit(): void {
    this.checkNetworkStatus();
    this.isGuest = this.sessionService.isGuest();
    this.mobileDevice = this.utilsService.getMobileOperatingSystem();
    if (this.sessionService.getPaymentInfoToast()) {
      this.sessionService.removePaymentInfoToast();
    }
    this.badge = environment.badge;
    this.enviromentname = environment.name;
    this.appVersion = environment.appVersion;
    this.currentRole = this.sessionService.getCurrentRole();
    this._user = this.sessionService.getUser();
    this.image_profil = this.services.utilsService.getServerUrl(
      this._user.image_profil.path
    );
    if (this.currentRole) {
      this.currentRoleType = this.services.roleService.getCurrentTypeRole();
    }

    this.$showloading = this.services.eventService.subscribe(
      'show:loading',
      () => {
        this.loadingEventsCount += 1;
      }
    );

    this.$hideloading = this.services.eventService.subscribe(
      'hide:loading',
      () => {
        if (this.loadingEventsCount > 0) this.loadingEventsCount -= 1;
      }
    );

    this.$showConversationLogs = this.services.eventService.subscribe(
      'show-conversation:logs',
      (buyedPackage: IBuyedpackage) => {
        this.buyedPackage = undefined;
        setTimeout(() => {
          this.buyedPackage = buyedPackage;
          this.sidenav.open();
        });
      }
    );

    this.showConversationLogsClose$ = this.services.eventService.subscribe(
      'show-conversation:logs:hide',
      () => {
        this.sidenav.close();
      }
    );

    this.breakpointMatcherService
      .initObserver('md')
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.breackpointMatche = true;
        } else {
          this.breackpointMatche = false;
        }
      });

    this.$updateGroup = this.services.eventService.subscribe(
      'group:update',
      (group: IGroup) => {
        this._user = this.sessionService.getUser();
        const role = this._user.roles!.find(role => role.group_id == group.id)!;
        if (this.currentRole.id === role.id) {
          this.currentRole = role;
          this.sessionService.storeCurrentRole(this.currentRole);
          this.currentRoleType = this.services.roleService.getCurrentTypeRole();
        }
      }
    );

    this.$rolechange = this.services.eventService.subscribe(
      'change:role',
      () => {
        this.currentRole = this.services.roleService.getCurrentRole();
        this.currentRoleType = this.services.roleService.getCurrentTypeRole();
      }
    );

    this.$updateUser = this.services.eventService.subscribe(
      'user:update',
      (user: IUser) => {
        this._user = user;
        this.image_profil = this.services.utilsService.getServerUrl(
          this._user.image_profil.path
        );
      }
    );

    this.$loginEnd = this.services.eventService.subscribe(
      'login:end',
      (user: IUser) => {
        this.isGuest = this.sessionService.isGuest();
        this._user = user;
        this.image_profil = this.services.utilsService.getServerUrl(
          this._user.image_profil.path
        );
        this.services.sessionService.deleteRequestToLogin();
      }
    );
  }

  toggleVisibility(): void {
    this.isVisible = !this.isVisible;
  }

  getRoleName(option: OptionObject): string {
    let role!: IRole;
    if (option.value != 'admin')
      role = this._user.roles!.find(role => role.id == Number(option.value))!;
    return role ? role.group.type : '';
  }

  hide(): void {
    this.isVisible = false;
  }

  openProfilDialog(): void {
    this.services.modalService.openModal(ProfileComponent, {
      width: '1300px',
      height: 'auto',
    });
  }

  logout(): void {
    this.sessionService.logout();
  }

  openReferalModlal(): void {
    this.services.modalService.openModal(ReferalModalComponent, {
      height: 'auto',
    });
  }

  openSignUpModal(): void {
    this.services.modalService
      .openModal(PreLoginInfosComponent, {
        width: '400px',
        height: 'auto',
        data: {
          caller: '_SIGN_UP',
        },
      })
      .subscribe(res => {
        if (
          res === '_SIGN_IN' ||
          (typeof res === 'object' && res.caller === '_SIGN_IN')
        ) {
          this.services.modalService.openModal(LoginModalComponent, {
            width: '950px',
            height: 'auto',
            data: {
              params: {
                ...(typeof res === 'object' && { identifier: res.state }),
              },
              caller: '_SIGN_IN',
            },
          });
        }
      });
  }

  openLoginModal(caller: string): void {
    this.sessionService.openLoginModal(undefined, undefined, caller);
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.$networkStatus = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.networkStatus = status;
        if (!this.networkStatus) {
          this.snackbar
            .open(
              this.currentLanguage === 'en'
                ? 'You are offline!'
                : 'Vous êtes hors ligne!',
              this.currentLanguage === 'en' ? 'Refresh' : 'Rafraîchir',
              {
                duration: 7000,
              }
            )
            .onAction()
            .subscribe(() => {
              window.location.reload();
            });
        }
      });
  }

  refreshApp(): void {
    window.location.reload();
  }
}
